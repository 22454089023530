import React from 'react'
import Styled from './styled.module.css'

export default function Content() {
    return (
        <div className="container">
            <section className={Styled.content}>
                <h1 className="sm-title bold">Thank you for visiting our Website, and reviewing our Web Site
            Disclaimer and Privacy Policy.</h1>
                <h2 className="sm-title bold">Copyright</h2>
                <p className="lg-text">
                    Unless otherwise indicated in this site, the information and materials available through this site is the property of Integra Global Solutions Corp (Integra) and/or its subsidiaries and affiliates and are protected under U.S. and foreign copyright, trademark and other intellectual property laws. No intellectual property, information and materials on this site are allowed to be transferred to visitors to this site. The content of this site may not be framed or incorporated into another Web site
                </p>
                <h2 className="sm-title bold">Information Security</h2>
                <p className="lg-text">
                    Integra keeps all your information confidential and do not disclose to any third parties. You are responsible for all activities that occur under your password or account, and will keep your password(s) confidential. You will immediately notify us of any unauthorized use of your password or account or any other breach of security. You will not disrupt the functioning of the Service, solicit another user's password, or otherwise act in a way that interferes with other users' use of the Service. Nor may you post or distribute any computer program that damages, detrimentally interferes with, surreptitiously intercepts, or expropriates any system, data, or personal information.
                </p>
                <h2 className="sm-title bold">Confidentiality</h2>
                <p className="lg-text">
                    All of client confidential information are on secure servers. Integra has taken reasonable steps to protect the confidential information from any unauthorized users. We treat confidential information given to us online with the same security that we do with information records on paper or over phone. We may provide this information for subpoenas, court or police orders or defend ourselves against a law suit. However, no technology is fail-proof. It is possible -- even if remote -- for an unauthorized person to gain access to this information despite the safeguard of a secure server.
                </p>
                <h2 className="sm-title bold">Email</h2>
                <p className="lg-text">
                    If you choose to send us e-mail that has included personal information, we will use the information only to respond to your message and for no other purposes. Your email is not shared with any other third party companies. Messages transmitted via electronic mail (e-mail) are not secure and we do not assume responsibility for any confidential information transmitted to this site via e-mail. Integra has exclusive rights of email or other forms of input provided by visitors to this site.
                </p>
                <h2 className="sm-title bold">Errors Disclaimer</h2>
                <p className="lg-text">
                    Integra does not warrant the accuracy, completeness, timeliness, non infringement, merchantability, suitability or fitness for a particular purpose of the information and materials made available through this site. To the maximum extent permitted by applicable law, in no event shall Integra be liable for any special, indirect, incidental or consequential damages whatsoever arising out of or resulting from the information or the errors in our Website/s. Integra may make changes to any product(s) or service(s) described in this site at any time. Integra does not guarantee that any product or service described in this site will be continuously available. The information on our site is periodically updated. The documents and related graphics published on this site could include typographical errors or technical inaccuracies.
                </p>
                <h2 className="sm-title bold">Privacy Policies of Web Sites Not Under Our Control</h2>
                <p className="lg-text">
                    For your convenience, our Website/s may provide links to various other web sites that we do not control. When you select one of these links, you will be transferred out of our Site and connected to the web site of the organization or company that you selected. We are not responsible for the nature, quality or accuracy of the content or opinions expressed in such web sites, and such web sites are not investigated, monitored or checked for quality, accuracy or completeness by us. If you visit a web site that is linked from our Site, and whenever interacting with any web site, we encourage you to consult that web site's privacy policy before providing any personal information.
                </p>
                <h2 className="sm-title bold">Personal information</h2>
                <p className="lg-text">
                    We will not automatically collect personal information about you when you visit our site unless you choose to provide that information to us. Cookies may be used to collect traffic information of visitors to our WebPages. We welcome links to our sites.
                </p>
                <h2 className="sm-title bold">The following privacy points are specific to comply with European GDPR requirements</h2>
                <h2 className="sm-title bold">Use of Data (GDPR requirement)</h2>
                <p className="lg-text">We use your collected data for various purposes:</p>
                <ul>
                    <li className="lg-text">To provide and maintain our Service</li>
                    <li className="lg-text">To notify you about changes to our Service</li>
                    <li className="lg-text">To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li className="lg-text">To provide customer support</li>
                    <li className="lg-text">To gather analysis or valuable information so that we can improve our Service</li>
                    <li className="lg-text">To monitor the usage of our Service</li>
                    <li className="lg-text">To detect, prevent and address technical issues</li>
                </ul>
                <h2 className="sm-title bold">Retention of Data</h2>
                <p className="lg-text">
                    We will retain your personal data only for as long as is necessary for the purposes set out to provide your services or other services that you have elected. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
                </p>
                <p className="lg-text">
                    We will also retain usage data for internal analysis purposes. Usage data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our service, or we are legally obligated to retain this data for longer time periods.
                </p>
                <h2 className="sm-title bold">Transfer Of Data</h2>
                <p className="lg-text">
                    Your information, including personal data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. This is done to perform the services that you have selected in this website.
                </p>
                <p className="lg-text">
                    Your consent to this privacy policy represents your agreement to this transfer.
                </p>
                <p className="lg-text">
                    We will take all steps necessary to ensure that your data is treated securely and in accordance with this data security and GDPR requirements policy and no transfer of your personal data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                </p>
                <h2 className="md-title bold">Disclosure Of Data</h2>
                <h2 className="sm-title bold">Disclosure for Law Enforcement</h2>
                <p className="lg-text">
                    Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
                </p>
                <h2 className="md-title bold">Legal Requirements</h2>
                <p className="lg-text">
                    We may disclose your Personal Data in the good faith belief that such action is necessary to:
                </p>
                <ul>
                    <li className="lg-text">
                        To comply with a legal obligation
                    </li>
                    <li className="lg-text">
                        To protect and defend the rights or property of Integra
                    </li>
                    <li className="lg-text">
                        To prevent or investigate possible wrongdoing in connection with the Service
                    </li>
                    <li className="lg-text">
                        To protect the personal safety of users of the Service or the public
                    </li>
                    <li className="lg-text">
                        To protect against legal liability
                    </li>
                </ul>
                <h2 className="md-title bold">Security Of Data</h2>
                <p className="lg-text">
                    The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
                </p>
                <h2 className="md-title bold">Your Rights</h2>
                <p className="lg-text">
                    We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
                </p>

                <p className="lg-text">
                    If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us at info at globalintegra.com
                </p>
                <p className="lg-text">In certain circumstances, you have the right:</p>
                <ul>
                    <li className="lg-text">To access and receive a copy of the Personal Data we hold about you</li>
                    <li className="lg-text">To rectify any Personal Data held about you that is inaccurate</li>
                    <li className="lg-text">To request the deletion of Personal Data held about you</li>
                </ul>
                <p className="lg-text">
                    You have the right to data portability for the information you provide to us. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.
                </p>

                <p className="lg-text">
                    Please note that we may ask you to verify your identity before responding to such requests.
                </p>
                <h2 className="md-title bold">Service Providers</h2>
                <p className="lg-text">
                    We regard your personal data as confidential and only share it if you provide your consent or if we are legally required to disclose it or pursuant to this Policy. Unless we are obliged or permitted by law to do so, personal data will not be disclosed to third parties contrary to this Policy.
                </p>

                <p className="lg-text">
                    We may, from time to time, employ the services of other parties for dealing with matters that may include, but are not limited to, payment handling, delivery of purchased items, search engine facilities, advertising and marketing. The providers of such services do have access to certain personal data provided by users of this Website or service.
                </p>

                <p className="lg-text">
                    Any personal data used by such parties is used only to the extent required by them to perform the services that we request. Any use for other purposes is strictly prohibited. Furthermore, any personal data that is processed by third parties must be processed within the terms of this policy and in accordance with the Data Protection Act 1998 and GDPR regulations.
                </p>
                <h2 className="md-title bold">Web site Analytics</h2>
                <p className="lg-text">
                    We may use third-party Service Providers to monitor and analyze how users behave on our website. As an example:
                </p>
                <p className="lg-text">
                    Google Analytics<br />
                    Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor how people use our website and the time spent on each of the webpages etc. This data may be shared with other Google services.
                </p>
                <p className="lg-text">
                    You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.

                </p>
                <p className="lg-text">
                    For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a target="_blank" href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
                </p>
                <h2 className="md-title bold">Remarketing</h2>
                <p className="lg-text">
                    We may at times use remarketing services to advertise our services to you on third party websites after you visited our website. We and our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our website.
                </p>
                <p className="lg-text">Google AdWords <br />
                    Google AdWords remarketing service is provided by Google Inc.</p>
                <p className="lg-text">
                    You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads Settings page: <a target="_blank" href="http://www.google.com/settings/ads" >http://www.google.com/settings/ads</a>
                </p>
                <p className="lg-text">
                    Google also recommends installing the Google Analytics Opt-out Browser Add-on - <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a> - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
              </p>
                <p className="lg-text">
                    For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a target="_blank" href="http://www.google.com/intl/en/policies/privacy/">http://www.google.com/intl/en/policies/privacy/</a>
                </p>
                <h2 className="md-title bold">Payments</h2>
                <p className="lg-text">
                    We may provide paid products and/or services. In that case, we use third-party services for payment processing (e.g. payment processors)
                </p>
                <p className="lg-text">
                    We do not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                </p>
                <p className="lg-text">
                    The payment processors we work with are: Paypal and Stripe. You can find the privacy policies of these sites at paypal.com and stripe.com
                </p>
                <p className="lg-text">
                    We appreciate the opportunity in working with you.
                </p>
                <p className="lg-text">
                    If you have any questions regarding linking to our sites or regarding the privacy statement please contact us.
                </p>
            </section>
        </div>
    )
}
